.audio-player-two {
  filter: invert(100%);
  background-color: #9d9c9c;
  padding: 0.2rem;
  border-radius: 5px;
}

.audio-player-two img {
  background-color: rgb(240, 93, 95);
  border-radius: 1rem;
  z-index: 2;
}

input[type=range].invert-blue-grey {
  -webkit-appearance: none;
  width: 100%;
  margin: 5px 0; 
}

input[type=range].invert-blue-grey:focus {
  outline: none;
}
input[type=range].invert-blue-grey::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #D8D8D8, 0px 0px 0px #0d0d0d;
  background: #D8D8D8;
  border-radius: 0px;
  border: 0px solid #D8D8D8;
}

input[type=range].invert-blue-grey::-webkit-slider-thumb {
  box-shadow: 0px 2px 6px rgba(0,0,0,.5), 0px 0px 0px #0d0d0d;
  border: none;
  height: 28px;
  width: 5px;
  border-radius: 50px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -12px;
  transition: all 0.2s ease 0s;
}

input[type=range].invert-blue-grey::-webkit-slider-thumb:hover {
  /* background: rgb(240, 93, 96); */
}

input[type=range].invert-blue-grey::-webkit-slider-thumb:active {
  width:  2px;
  height: 40px;
  margin-top: -18px;
}

input[type=range].invert-blue-grey:focus::-webkit-slider-runnable-track {
  /* background: #000000; */
}

input[type=range].invert-blue-grey::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #000000;
  border-radius: 0px;
  border: 0px solid #000000;
}

input[type=range].invert-blue-grey::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: none;
  height: 11px;
  width: 11px;
  border-radius: 50px;
  background: rgb(240, 93, 95);
  cursor: pointer;
}

input[type=range].invert-blue-grey::-moz-range-thumb:hover {
  background: black;
}

input[type=range].invert-blue-grey::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range].invert-blue-grey::-ms-fill-lower {
  background: #000000;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type=range].invert-blue-grey::-ms-fill-upper {
  background: #000000;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type=range].invert-blue-grey::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: none;
  height: 11px;
  width: 11px;
  border-radius: 50px;
  background: rgb(240, 93, 95);
  cursor: pointer;
  height: 1px;
}

input[type=range].invert-blue-grey::-ms-thumb:hover {
  background: black;
}

input[type=range].invert-blue-grey:focus::-ms-fill-lower {
  background: #000000;
}

input[type=range].invert-blue-grey:focus::-ms-fill-upper {
  background: #000000;
}

  #play {
    position: relative;
    width: 22px;
    height: 0;
    cursor: pointer;
  }
  
  #play,
  #volume {
    opacity: 1;
    transition: all 0.2s ease 0s;
  }
  
  #play:hover,
  #volume:hover {
    opacity: .6;
  }
  
  #play:active,
  #volume:active {
    transform: scale(0.8);
  }
   /*
  #play:after {
    content: ' ';
    position: absolute;
    top:  -12px;
    left: 0;
    width:  24px;
    height: 24px;
    background: url('../../images/icon_play.svg') center center no-repeat;
  }
  #play img {
    display: none;
  } */

  .audio-player-time {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 500;
    color: black;
    font-size: 14px;
    margin-left: 14px;
  }

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: #8a8a8a;
  }

  .audio-player-seeker,
  .slide-container {
    margin: 0 !important;
  }

  .audio-player-volume {
    width: 140px !important;
    /* margin-left: 30%; */
    position: absolute;
    right: 38px !important;
  }

  #volume {
    margin-right: 20px;
    width: 40px;
    cursor: pointer;
  }

  .audio-player {
    background: #fff;
  }